import React from "react";

// reactstrap misc
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import CoreEngine from "../core/CoreEngine";
import RequestEngine from "../core/RequestEngine";


class Dashboard extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clientcount : 0,
            expertcount : 0,
            requestcount : 0,
            offercount:0,
            lastestrequest : [],
            latestoffer : []
        };
    }


    componentDidMount() {
        this.engine = new RequestEngine();
        this.callPage()
    }

    callPage() {

        this.engine.getdashboard((response) => {
            if(response.status === 200){
                this.setState({
                    isLoading: false,
                    clientcount :  response.data.data.clientcount,
                    expertcount :  response.data.data.expertcount,
                    requestcount :  response.data.data.requestcount,
                    offercount: response.data.data.offercount,
                    lastestrequest :  response.data.data.lastestrequest,
                    latestoffer :  response.data.data.latestoffer,
                });
            }
        });
    }




  render() {
      const {   clientcount ,
          expertcount ,
          requestcount ,
          offercount,
          lastestrequest ,
          latestoffer ,isLoading} = this.state;
      if (isLoading) {
          return this.renderProgress()
      }
    return (
      <>
          <div className="content english">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-warning" />
                      </div>
                    </Col>
                    <Col md="9" xs="7">
                      <div className="numbers">
                        <p className="card-category">Clients</p>
                        <CardTitle tag="p">{clientcount}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                  <CardFooter>
                      <hr />

                  </CardFooter>

              </Card>
            </Col>


            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="5">
                      <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-single-02 text-warning" />
                      </div>
                    </Col>
                    <Col md="9" xs="7">
                      <div className="numbers">
                        <p className="card-category">Experts</p>
                        <CardTitle tag="p">{expertcount}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />

                </CardFooter>
              </Card>
            </Col>


              <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                      <CardBody>
                          <Row>
                              <Col md="3" xs="5">
                                  <div className="icon-big text-center icon-warning">
                                      <i className="nc-icon nc-paper text-success" />
                                  </div>
                              </Col>
                              <Col md="9" xs="7">
                                  <div className="numbers">
                                      <p className="card-category">Request</p>
                                      <CardTitle tag="p">{requestcount}</CardTitle>
                                      <p />
                                  </div>
                              </Col>
                          </Row>
                      </CardBody>
                      <CardFooter>
                          <hr />

                      </CardFooter>
                  </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                      <CardBody>
                          <Row>
                              <Col md="3" xs="5">
                                  <div className="icon-big text-center icon-warning">
                                      <i className="nc-icon nc-paper text-success" />
                                  </div>
                              </Col>
                              <Col md="9" xs="7">
                                  <div className="numbers">
                                      <p className="card-category">Offers</p>
                                      <CardTitle tag="p">{offercount}</CardTitle>
                                      <p />
                                  </div>
                              </Col>
                          </Row>
                      </CardBody>
                      <CardFooter>
                          <hr />

                      </CardFooter>
                  </Card>
              </Col>
          </Row>

            <Row>
                <Col md="6">
                    <Card className="card-tasks">
                        <CardHeader>
                            <CardTitle tag="h4">New request</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="table-full-width table-responsive">
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th >Title</th>
                                        <th >Client</th>
                                        <th>Created</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lastestrequest && lastestrequest.map((item, index) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {item.title}
                                                </td>
                                                <td >
                                                    {item.user && item.user.first+" "+item.user.last}
                                                </td>
                                                <td className="td-actions ">
                                                    {this.renderDate(item.createdAt)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <hr />

                        </CardFooter>
                    </Card>
                </Col>

                <Col md="6">
                    <Card className="card-tasks">
                        <CardHeader>
                            <CardTitle tag="h4">New Offer</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="table-full-width table-responsive">
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th >Title</th>
                                        <th >Expert</th>
                                        <th  >Created</th>
                                    </tr>
                                    </thead>


                                    <tbody>
                                    {latestoffer && latestoffer.map((item, index) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {item.title}
                                                </td>

                                                <td >
                                                    {item.user && item.user.first+" "+item.user.last}
                                                </td>
                                                <td className="td-actions ">
                                                    {this.renderDate(item.createdAt)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <hr />

                        </CardFooter>
                    </Card>
                </Col>

            </Row>


        </div>
      </>
    );
  }
}

export default Dashboard;
