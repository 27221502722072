import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,

    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import {confirmAlert} from "../../components/Confirm";

class PaymentList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
        };
        this.pathname = "payment";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage(0)
    }

    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data
        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    async handleDelete(valueid) {

        confirmAlert({
            title: 'Delete ',
            message: 'Are you sure to want to delete  ?',
            buttons: [
                {
                    label: 'Yes',
                    className:"redmin",
                    onClick: async () => {

                        this.props.loadingAction(true)
                        const result = await this.engine.deleteItem(this.pathname,valueid)
                        if(result){
                            this.handlePage();
                        }
                        this.props.loadingAction(false)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });

    }
    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>


            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    async callPage(page,filtered = []) {

        const response = await  this.engine.postItem(this.pathname,"/list/"+page,filtered);
        if(response && response.status === 200){
            this.setState({ // cname,cemail,cposition,cdesc,category,nationality,address,website
                    isLoading: false,
                    count:response.data.data.count,
                    page:page,
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            catid:item._id,
                            data:item,
                            status:item.status,
                            method:item.method,
                            lastpayment:item.lastpayment?"last payment":"initial payment",
                            offer: item.offer && item.offer.title ,
                            request: item.request && item.request.title ,
                            user: item.user && item.user.full_name,
                            usertype: item.user && item.user.usertype,
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage(page) {
        this.props.loadingAction(true);
        this.callPage(page)
    }




    render() {
        const {dataSource,isLoading,count,page} = this.state;



        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })

        let columns = [

            {
                Header: "User",
                accessor: "user",
                sortable: false,
                filterable: false,
            },
            {
                Header: "User Type",
                accessor: "usertype",
                sortable: false,
                filterable: false,
                className:"bold"
            },

            {
                Header: "Payment Type",
                accessor: "lastpayment",
            },
            {
                Header: "Payment method",
                accessor: "method",
            },

            {
                Header: "request",
                accessor: "request",
            },
            {
                Header: "offer",
                accessor: "offer",
            },
            {
                Header: "status",
                accessor: "status",
            },

            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                </CardHeader>
                                <CardBody>

                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        onPageChange={(pageIndex) => {
                                            console.log(pageIndex)
                                        }}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        //showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />



                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(PaymentList);
