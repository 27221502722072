import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";

class AssignedSelection extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            selected:[],
        };
        this.pathname = "expert";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }






    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data
        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {
        return <div>
            <input type={"checkbox"}/>
        </div>
    }

    async callPage() {

        const response = await  this.engine.getItem(this.pathname);

        const {assigned} = this.props

        if(response && response.status === 200){

            response.data.data.map(i=>{
                if(assigned.includes(i._id)){
                    this.toggleRow(i._id)
                }
            })

            this.setState({ // cname,cemail,cposition,cdesc,category,nationality,address,website
                    isLoading: false,
                    dataSource: response.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            name: item.full_name ,
                            major: item.major,
                            email: item.email,
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }

    handleAdd() {
        const{ selected} = this.state
        console.log("selected",selected)

        this.props.onPick(selected)
    }



    toggleRow(id) {
        debugger
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected,
        });
    }
    render() {
        const {dataSource,isLoading} = this.state;

        let columns = [
            {
                Header: "#",
                accessor: "actions",
                sortable: false,
                filterable: false,
                Cell: ({ original }) => {
                    //debugger
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selected[original.data.id] === true}
                            onChange={() => this.toggleRow(original.data.id)}
                        />
                    );
                },

            },
            {
                Header: "Title",
                accessor: "name"
            },
            {
                Header: "Email",
                accessor: "email"
            },
            {
                Header: "Major",
                accessor: "major"
            },
            {
                Header: "Created",
                accessor: "created"
            },

        ]


        return (
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Button color="primary" className="pull-right" onClick={() =>
                                this.handleAdd()
                            }>
                                Confirm
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={dataSource}
                                defaultFilterMethod={this.filterCaseInsensitive}
                                filterable
                                loading={isLoading}
                                columns={columns}
                                defaultPageSize={10}
                                //showPaginationTop
                                showPaginationBottom={true}
                                /*
                                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                */
                                className="-striped -highlight primary-pagination"
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(AssignedSelection);
