import React from "react";

// reactstrap misc
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import blacklogo from "assets/img/logo.png";
import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";
import { HollowDotsSpinner } from 'react-epic-spinners'
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

class Login extends CoreEngine {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password:"",
            managertype : "",
            token : "",
            loading : false,
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

    async login(){

        const {username,password} = this.state

       // this.props.history.push('/admin/dashboard');
       // return;

        try {
            this.setState({loading : true})

            const result = await this.engine.login(username,password)

            if(result.status === 200){

                const managertype = result.data.data.managertype.trim().toLowerCase();
                const token = result.data.data.token;

                sessionStorage.setItem('managertype', managertype);
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('isloggedin', true);
                // redirect to dashboard
                console.log("login")
                console.log("pressLogin")

                    this.props.history.push('/admin/dashboard');

            }else{
                alert("Wrong login info  ")
                this.setState({password:""})
            }
        }catch (e) {
            alert("Wrong login info  ")
            this.setState({password:"",loading:false})
        }

        this.setState({loading : false})
    }
  render() {
        const {username,password,loading} = this.state
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <div className="logo-img">
                          <img src={blacklogo} alt="react-logo" style={{width:150}} className="innerLogo" />
                      </div>

                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Username" value={username} onChange={e =>
                          this.changeInput(e, "username")
                      } type="text" />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={e =>
                            this.changeInput(e, "password")
                        }
                      />
                    </InputGroup>
                      <div style={{textAlign:"center"}}>
                          <ButtonLoader onClick={() => this.login()} color="primary" loading={loading}>Login</ButtonLoader>
                      </div>
                  </CardBody>

                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`
            //  backgroundImage: `url(${require("assets/img/bg/why-was-bitcoin-invented-1024x640.jpg")})`

          }}
        />
      </div>
    );
  }
}

export default Login;
