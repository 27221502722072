import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Button,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";

export default class OfferListAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title:"",
            description:"",
            _id:"",
            loading : false,
            status:"",
            currency:"USD",
            user:"",
            milestones:[{}],
            clientlist:[],
            expertlist:[],
            comment:""
        };
        this.pathname = "offer";
        this.engine = new RequestEngine();

        this.milestonestitle=[]
        this.milestonesdescription=[]
        this.milestonesduration=[]
        this.milestonescost=[]
    }

    componentDidMount() {

        const edit = this.props.location.data

        if (edit){
            this.updateValues(edit)
        }else{
            this.prepareData()
        }

        this.callPage()
    }
    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+id);
        if(response && response.status === 200){
            this.updateValues(response.data.data)
            this.setState({
                    isLoading: false,
                }
            );
        }
    }

    updateValues(edit){
        if(edit.milestonestitle){
            this.milestonestitle=edit.milestonestitle
            this.milestonesdescription=edit.milestonesdescription
            this.milestonesduration=edit.milestonesduration
            this.milestonescost=edit.milestonescost
            this.milestonesmarkup=edit.milestonesmarkup

            debugger
            let milestones = []
            edit.milestonescost && edit.milestonescost.map(i=>{
                milestones.push({})
            })
            this.setState({
                milestones
            });
        }
        this.setState({
            ...edit,
            user:edit.user && edit.user._id
        },()=>{
            this.prepareData()
        });
    }



    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("expert");
        if(responseuni){
            const data = responseuni.data.data;
            let expertlist = []
            data.map(p =>{
                    expertlist.push( { value: p._id, label: p.full_name})
                }
            )

            this.setState({
                expertlist: expertlist
            });
        }


    }





    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })
        const {title,request,comment,_id,status,currency} = this.state


        let data = {
            _id:_id,
            title:title,
            request:request,
            comment:comment,
            status:status,
            currency:currency,
            milestonestitle:this.milestonestitle,
            milestonesdescription:this.milestonesdescription,
            milestonesduration:this.milestonesduration,
            milestonescost:this.milestonescost,
            milestonesmarkup:this.milestonesmarkup,

        }



        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    milestoneChangeValue = (event, stateName,index) => {

        let servicefound = this[stateName][index]

        let value = ""
        if(event && event.target){
            value = event.target.value;
        }else if(event && event.length){
            value = event;
        }else{
            value = event.value;
        }
        if(servicefound){
            servicefound.value =  value;
        }else{
            this[stateName].push({key:stateName+index,value:value})
        }
        this.forceUpdate()
    };

    renderMileStone(){
        const {milestones} = this.state
        return (<div>

            {milestones.map((item,index)=>{

                let value = this.milestonestitle && this.milestonestitle[index]
                let milestonestitle = ""
                if(value){
                    milestonestitle =  value.value;
                }

                value = this.milestonesdescription && this.milestonesdescription[index]
                let milestonesdescription = ""
                if(value){
                    milestonesdescription =  value.value;
                }

                value = this.milestonesduration && this.milestonesduration[index]
                let milestonesduration = ""
                if(value){
                    milestonesduration =  value.value;
                }

                value = this.milestonescost && this.milestonescost[index]
                let milestonescost = ""
                if(value){
                    milestonescost =  value.value;
                }

                value = this.milestonesmarkup && this.milestonesmarkup[index]
                let milestonesmarkup = "0"
                if(value){
                    milestonesmarkup =  value.value;
                }



                return  <div key={index}>
                    <div>

                        <Row><Label sm={2}>Miltestone #</Label>
                            <Col sm={1} >
                                <FormGroup >
                                    <b>{1+index}</b>
                                </FormGroup>
                            </Col>
                            <Col sm={3} style={{padding: 0}}>
                                {index>0 &&<Button color="danger"  onClick={()=>{

                                    milestones.splice(index,1)
                                    this.milestonestitle.splice(index,1)
                                    this.milestonesdescription.splice(index,1)
                                    this.milestonesduration.splice(index,1)
                                    this.milestonescost.splice(index,1)
                                    this.milestonesmarkup.splice(index,1)



                                    this.setState({
                                        milestones
                                    })
                                }}>
                                    Delete
                                </Button>}
                            </Col>

                        </Row>

                    </div>

                    <FormRow label="Milestone Title" name={"milestonestitle"+index} data={milestonestitle}   changeInput={(e)=>this.milestoneChangeValue(e,"milestonestitle",index)} />
                    <FormRow label="Milestone Description" type={"textarea"} name={"milestonesdescription"+index} data={milestonesdescription}   changeInput={(e)=>this.milestoneChangeValue(e,"milestonesdescription",index)}  />

                    <Row>

                        <Col md="4" >
                            <FormRow label="Cost " type={"number"} name={"milestonescost"+index} data={milestonescost}   changeInput={(e)=>this.milestoneChangeValue(e,"milestonescost",index)}  />
                        </Col>
                        <Col md="4" >
                            <FormRow label="Duration (Days)" type={"number"} className={"mr-2"} name={"milestonesduration"+index} data={milestonesduration}  changeInput={(e)=>this.milestoneChangeValue(e,"milestonesduration",index)}   />

                        </Col>
                        <Col md="4" >
                            <FormRow label="Markup " required={false}  type={"number"} name={"milestonesmarkup"+index} data={milestonesmarkup}  changeInput={(e)=>this.milestoneChangeValue(e,"milestonesmarkup",index)}    />
                        </Col>
                    </Row>
                    <hr/>
                </div>

            })}

            <Row>
                <Col md="10" >
                </Col>
                <Col md="2" >
                    <Button color="primary" className="pull-right" onClick={()=>{
                        milestones.push({})

                        this.milestonestitle.push({})
                        this.milestonesdescription.push({})
                        this.milestonesduration.push({})
                        this.milestonescost.push({})
                        this.setState({
                            milestones
                        })
                    }}>
                        + Add
                    </Button>
                </Col>

            </Row>


        </div>)
    }


    render() {
        // taking all the states
        const {loading,isLoading,  title,description,user,
            assigned,
            clientlist,
            expertlist,
            expertise,
            status,
            budget,
            duration,
            comment ,currency}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Title"  name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="Currency"  name={"currency"}  defaultValue={currency}  data={this.createSelectValue(["USD","LBP"])} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="expert"  name="user"  defaultValue={user}  data={expertlist} changeInput={this.changeInput.bind(this)} />



                                        <SelectRow label="status"  name="status"  defaultValue={status}  data={this.createSelectValue(["pending configuration","pending","retracted","approved","canceled","awarded","declined"])} changeInput={this.changeInput.bind(this)} />


                                        <FormRow label="comments" required={false} name="comments" data={comment}  type={"textarea"}  changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardHeader>
                                        <CardTitle tag="h4">Milestones</CardTitle>
                                    </CardHeader>

                                    <CardBody>


                                        {this.renderMileStone()}

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Cancel</Button> &nbsp;
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


