import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Row,
    Modal,
    Col, Button
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import {selectCountryList} from "../../core/countries";
import FileUpload from "../../components/file-upload/file-upload.component";


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import {AvForm, AvGroup} from 'availity-reactstrap-validation';

import {Utilites} from "../../core/Utilites";
import AssignedSelection from "./AssignedSelection";

export default class RequestAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title:"",
            description:"",
            othercategorydescription:"",
            deadline:"",
            startdate:"",
            _id:"",
            loading : false,
            status:"",
            category:"",
            randdtype:"",
            user:"",
            randdproducts:"",
            currency:"USD",
            assigned:[],
            clientlist:[],
            expertlist:[],

            attachements:[],
            attachments_url:[],
            expertise:"",
            budget:"",
            duration:"",
            comments:"",

            randdprojectstatus:"",
            randdlevel:"",
            randdchallenge:"",
            fsmstype:"",
            fsmslevel:"",
            fsmschallenge:"",
            fsmsmeasure:"",
            fsmsdoc:"",
            certificationtype:"",
            certificationlevel:"",
            certificationchallenge:"",
            certificationmeasure:"",
            certificationdoc:"",
            certificationexpected:"",
            tradetype:"",
            tradecountry:[],
            tradetime:"",
            traderequest:"",
            tradeactivities:"",
            tradeproject:"",
            trainingtype:"",
            trainingtopic:"",
            trainingnumber:"",
            trainingwork:"",
            traininglevel:"",
            trainingpreivous:"",
            trainingtooltip:"",
            trainingtimeline:"",
            trainingdaytime:"",
            traininglocation:"",
            traininglocationother:"",

            randdchallengeyes:"",
            fsmsdocyes:"",
            fsmschallengeyes:"",
            certificationchallengeyes:"",
            certificationdocyes:"",
            trainingpreivousyes:"",

            modalClassic: false,
        };
        this.pathname = "request";
        this.engine = new RequestEngine();

        this.idFileBase64Attach = [];
    }


    toggleModalMapClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };

    renderMapCode() {
        const {modalClassic,assigned} = this.state;
        return (<>
            <Modal
                isOpen={modalClassic}
                toggle={this.toggleModalMapClassic}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassic}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">Assigned Expert</h4>
                </div>
                <div className="modal-body">
                    <AssignedSelection assigned={assigned} onPick={this.onPick.bind(this)}/>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic}
                        >
                            Hide
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }

    renderRandD() {

        const {
            randdchallengeyes,
            randdproducts,
            otherranddtype,
            randdtype,
            randdprojectstatus,
            randdlevel,
            randdchallenge}= this.state;
        return (<div>


                    <SelectRow label="Type of optimization requested"  required={false}   name="randdtype"  className={"mr-2"} defaultValue={randdtype}  data={this.createSelectValue(["shelf life/quality/packaging optimization of existing product","new product development ","process line optimization ","trials","Other"])} changeInput={this.changeInput.bind(this)} />
                {randdtype=="Other" && <FormRow label="Other Type"  type={"textarea"} name="otherranddtype" data={otherranddtype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                }
                <FormRow label="Products"  type={"textarea"} required={true} name="randdproducts" data={randdproducts} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    <SelectRow label="Project status "  required={false}   name="randdprojectstatus"  className={"mr-2"} defaultValue={randdprojectstatus}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the research/trials/optimization "])} changeInput={this.changeInput.bind(this)} />

                    <FormRow label="High level process steps & conditions"   type={"textarea"} name="randdlevel" data={randdlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Challenge/issue/problem faced"  required={false}   name="randdchallenge"  className={"mr-2"} defaultValue={randdchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {randdchallenge=="yes" && <FormRow type={"textarea"} label="Please Write it here"  name="randdchallengeyes" data={randdchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

        </div>)

    }
    renderFSMS() {

        const {
            fsmsdocyes,
            fsmschallengeyes,
            fsmstype,
            fsmslevel,
            fsmschallenge,
            fsmsmeasure,
            fsmsdoc,}= this.state;
        return (<div>

                    <FormRow label="Type of service" type={"textarea"} name="fsmstype" data={fsmstype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow  label="High level process steps & conditions surrounding the production line"  type={"textarea"} name="fsmslevel" data={fsmslevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Challenge/issue/problem faced"  required={false}   name="fsmschallenge"  className={"mr-2"} defaultValue={fsmschallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {fsmschallenge=="yes" && <FormRow type={"textarea"} label="Please Write it here"  name="fsmschallengeyes" data={fsmschallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                    <FormRow label="Current measures taken"  type={"textarea"} name="fsmsmeasure" data={fsmsmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Documentation in place"  required={false}   name="fsmsdoc"  className={"mr-2"} defaultValue={fsmsdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {fsmsdoc=="yes" && <FormRow label="Please Write it here" type={"textarea"}  name="fsmsdocyes" data={fsmsdocyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

        </div>)

    }
    renderCERTIFICATION() {

        const {
            certificationdocyes,
            certificationchallengeyes,
            certificationtype,
            certificationlevel,
            certificationchallenge,
            certificationmeasure,
            certificationdoc,
            certificationexpected,}= this.state;
        return (<div>
                    <SelectRow label="Type of service"  required={false}   name="certificationtype"  className={"mr-2"} defaultValue={certificationtype}  data={this.createSelectValue(["gap analysis"," pre-certification","pre-audit "])} changeInput={this.changeInput.bind(this)} />

                    <FormRow label="High level process steps & conditions"  type={"textarea"} name="certificationlevel" data={certificationlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />


                    <SelectRow label="Challenge/issue/problem faced"  required={false}   name="certificationchallenge"  className={"mr-2"} defaultValue={certificationchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {certificationchallenge=="yes" && <FormRow type={"textarea"} label="Please Write it here"  name="certificationchallengeyes" data={certificationchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


                    <FormRow label="Current measures taken"  type={"textarea"} name="certificationmeasure" data={certificationmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Documentation in place"  required={false}   name="certificationdoc"  className={"mr-2"} defaultValue={certificationdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {certificationdoc=="yes" && <FormRow label="Please Write it here"  name="certificationdocyes" data={certificationdocyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                    <SelectRow label="Certification expected "  required={false}   name="certificationexpected"  className={"mr-2"} defaultValue={certificationexpected}  data={this.createSelectValue(["HACCP","ISO22000","FSSC22000","ISO9001","BRC","Global GAP"," Organic certification"])} changeInput={this.changeInput.bind(this)} />

        </div>)

    }
    renderTrade() {

        const {
            othertraderequest,
            tradetype,
            tradecountry,
            tradetime,
            traderequest,
            tradeactivities,
            tradeproject}= this.state;
        return (<div>
                    <SelectRow label="Type of service"  required={false}   name="tradetype"  className={"mr-2"} defaultValue={tradetype}  data={this.createSelectValue(["import","export"])} changeInput={this.changeInput.bind(this)} />

            <SelectRow label="Country" isMulti={true}  required={true}   name="tradecountry"  className={"mr-2"} defaultValue={tradecountry}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />

            <SelectRow label="Time in trade"  required={false}   name="tradetime"  className={"mr-2"} defaultValue={tradetime}  data={this.createSelectValue(["import","export"])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Request"  required={false}   name="traderequest"  className={"mr-2"} defaultValue={traderequest}  data={this.createSelectValue(["food safety & quality regulations compliance (FDA, EU, CFIA, GSO..."," label creatio","label review","packaging compliance","Other"])} changeInput={this.changeInput.bind(this)} />
                    {traderequest=="Other" &&
                    <FormRow label="Other Request"  type={"textarea"} name="othertraderequest" data={othertraderequest} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }

                    <FormRow label="Activities/Products"  type={"textarea"} name="tradeactivities" data={tradeactivities} className={"mr-2"} type={"textarea"}    changeInput={this.changeInput.bind(this)} />


                    <SelectRow label="Project status"  required={false}   name="tradeproject"  className={"mr-2"} defaultValue={tradeproject}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the compliance/labelling/packaging - please specify what activities you have already implemented in relation to this project request "])} changeInput={this.changeInput.bind(this)} />

        </div>)

    }
    renderTraining() {

        const {
            trainingpreivousyes,
            trainingtype,
            trainingtopic,
            trainingnumber,
            trainingwork,
            traininglevel,
            trainingpreivous,
            trainingtooltip,
            trainingtimeline,
            trainingdaytime,
            traininglocation,traininglocationother}= this.state;
        return (<div>
                    <SelectRow label="Type of training"  required={false}   name="trainingtype"  className={"mr-2"} defaultValue={trainingtype}  data={this.createSelectValue(["Individual","company","cluster/group"])} changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Topic of training" type={"textarea"}  name="trainingtopic" data={trainingtopic} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Number of trainees" type={"number"}  name="trainingnumber" data={trainingnumber} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />


                    <FormRow label="Type of work the trainees perform" type={"textarea"}  name="trainingwork" data={trainingwork} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Level of knowledge of the trainees" type={"textarea"}  name="traininglevel" data={traininglevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Previous training received"  required={false}   name="trainingpreivous"  className={"mr-2"} defaultValue={trainingpreivous}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {trainingpreivous=="yes" && <FormRow label="Please Write it here"  name="trainingpreivousyes" data={trainingpreivousyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


                    <FormRow label="Specific consideration"  type={"textarea"} name="trainingtooltip" data={trainingtooltip} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Timeline"  required={false}   name="trainingtimeline"  className={"mr-2"} defaultValue={trainingtimeline}  data={this.createSelectValue(["one-time training","recurrent training "])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="day time"  required={false}   name="trainingdaytime"  className={"mr-2"} defaultValue={trainingdaytime}  data={this.createSelectValue(["am","pm"])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Location to conduct the training"  required={false}   name="traininglocation"  className={"mr-2"} defaultValue={traininglocation}  data={this.createSelectValue(["online","in person"])} changeInput={this.changeInput.bind(this)} />
            {traininglocation=="in person" && <FormRow required={true} label="Please specify the location"  name="traininglocationother" data={traininglocationother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

        </div>)

    }
    componentDidMount() {

        const edit = this.props.location.data
        if (edit){

            edit.attachments_url && edit.attachments_url.map(i=>{
                this.idFileBase64Attach.push(i);
            })

            this.setState({
                ...edit,
                user:edit.user && edit.user._id,
            },()=>{
                this.prepareData()
            });
        }else{
            this.prepareData()
        }
        this.callPage()
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+id);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    ...response.data.data,
                }
            );
        }
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("client");
        if(responseuni){
            const data = responseuni.data.data;
            let clientlist = []
            data.map(p =>{
                clientlist.push( { value: p._id, label: p.full_name})
                }
            )

            this.setState({
                clientlist: clientlist
            });
        }

        responseuni = await this.engine.getItemlistDirect("expert");
        if(responseuni){
            const data = responseuni.data.data;
            let expertlist = []
            data.map(p =>{
                expertlist.push( { value: p._id, label: p.full_name})
                }
            )
            this.setState({
                expertlist: expertlist
            });
        }


    }



    handleValidSubmit = async () => {


        debugger
        this.setState({
            loading: true
        })


        debugger
        let data = {...this.state}


        data.attachements = this.idFileBase64Attach;
        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };

    updateUploadedFiles(files,filename,authorized=false){
        this.idFileBase64Attach = [];
        files.map(i=>{
            if(!i.url){
                Utilites.getFileBase64(i, (result) => {
                    if(i.name==filename){
                        this.idFileBase64Attach.push({base64:result,name:i.name,authorized:authorized});
                    }else{
                        this.idFileBase64Attach.push({base64:result,name:i.name,authorized:false});
                    }
                });
            }else{
                this.idFileBase64Attach.push(i);
            }
        })
    }

    updateFilesAuthorised(selectedindex,authorized=false){
       // this.idFileBase64Attach = [];
        debugger
                 if(this.idFileBase64Attach[selectedindex]) {
                     this.idFileBase64Attach[selectedindex].authorized = authorized;
                 }
    }

    onPick(data){
        let assigned = []
        for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
            if(value){
                assigned.push(key)
            }
        }
        this.setState({
            assigned:assigned,
            modalClassic:false

        })


    }

    render() {
        // taking all the states
        const {loading,isLoading,  title,attachments_url,user,
            assigned,
            clientlist,
            expertlist,
            expertise,
            status,
            budget,
            category,
            duration,
            currency,
            deadline,
            startdate,
            outcome,
            comments ,othercategory,othercategorydescription}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    {this.renderMapCode()}
                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Title"  name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="client"  name="user"  defaultValue={user}  data={clientlist} changeInput={this.changeInput.bind(this)} />



                                        <SelectRow label="status"  name="status"  defaultValue={status}  data={this.createSelectValue(["pending","approved","canceled","awarded","awarded/pending payment confirmation"])} changeInput={this.changeInput.bind(this)} />
                                        {status!="pending" && status!="canceled" &&   <div>

                                            <SelectRow label="assigned" readonly={true} viewonly={true} isMulti={true}  name="assigned"   data={expertlist} defaultValue={assigned && assigned.map((item) => {
                                                return item && expertlist.find(v => v.value === item.value || v.value === item)
                                            })} changeInput={data =>
                                                this.setState({ assigned: data },()=>{
                                                })
                                            }  />

                                            <div style={{
                                                position: "absolute",
                                                marginTop: -44,
                                                marginLeft: 77,
                                            }}>
                                                <Button color="primary" className="pull-right" onClick={() =>
                                                    this.setState({
                                                        modalClassic:true
                                                    })
                                                }>
                                                    Select
                                                </Button>
                                            </div>


                                            </div>}


                                        <SelectRow label="Category"  info={"Choose any category that most closely resembles your request/project, and if it fits multiple or not, choose the closest option and explain the rest in the text field below."} name="category"  defaultValue={category}  data={this.createSelectValue(["Research & Development, Process Optimization &  New Products Designs","Design & Documentation of Food Safety Management Systems","Food Safety & Quality Systems Pre-Certification & Audits Preparation","Technical Assistance on Food Trade Regulations","Training","Other"])} changeInput={this.changeInput.bind(this)} />

                                        <hr/>
                                        {category=="Other" && <FormRow label="Other Category"  name="othercategory" data={othercategory} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                                        {category=="Other" && <FormRow label="Project Description "  name="othercategorydescription" data={othercategorydescription} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                                        {category.includes("search") && this.renderRandD()}
                                        {category.includes("ocumentation") && this.renderFSMS()}
                                        {category.includes("ertification") && this.renderCERTIFICATION()}
                                        {category.includes("ssistance") && this.renderTrade()}
                                        {category.includes("aining") && this.renderTraining()}
                                        <hr/>
                                        <SelectRow label="Expertise" info={"What expertise level suits you"}  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(["Fresh Graduate","Junior (3-5 years of experience)","Senior (5-10 years of experience)","Managerial (10+ years of experience)"])} changeInput={this.changeInput.bind(this)} />


                                            <SelectRow label="Currency"    name="currency"  defaultValue={currency}  data={this.createSelectValue(["USD","LBP"])} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"budget ("+currency+")"}  name="budget" data={budget}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Duration (Days)"  name="duration" data={duration}  type={"number"}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Start date" type={"date"}  name="startdate" data={startdate}    changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="End Date"  name="deadline" data={deadline}  type={"date"}  changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="Outcome / Result Expected" required={false} type={"textarea"} name="outcome" data={outcome}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Additional Description or Comments" required={false} type={"textarea"} name="comments" data={comments}   changeInput={this.changeInput.bind(this)} />

                                        <Row>
                                            <Col sm="12">
                                                <FileUpload
                                                    accept=".jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx"
                                                    label="If you have any project related documents that would help us better determine the scope of work, please attach them."
                                                    multiple
                                                    updateFilesCb={this.updateUploadedFiles.bind(this)}
                                                    updateFilesAuthorised={this.updateFilesAuthorised.bind(this)}

                                                    oldvalue={attachments_url}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Cancel</Button> &nbsp;
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>


                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


