import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Button,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
export default class OfferView extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title:"",
            description:"",
            _id:"",
            loading : false,
            status:"",
            user:"",
            currency:"USD",
            milestones:[{}],
            clientlist:[],
            expertlist:[],
            comment:""
        };
        this.pathname = "offer";
        this.engine = new RequestEngine();

        this.milestonestitle=[]
        this.milestonesdescription=[]
        this.milestonesduration=[]
        this.milestonescost=[]
        this.milestonesmarkup=[]
    }

    componentDidMount() {

        const edit = this.props.location.data

        if (edit){
            this.updateValues(edit)
        }else{
            this.prepareData()
        }
        this.callPage()
    }

    updateValues(edit){
        if(edit.milestonestitle){
            this.milestonestitle=edit.milestonestitle
            this.milestonesdescription=edit.milestonesdescription
            this.milestonesduration=edit.milestonesduration
            this.milestonescost=edit.milestonescost
            this.milestonesmarkup=edit.milestonesmarkup

            let milestones = []
            edit.milestonescost && edit.milestonescost.map(i=>{
                milestones.push({})
            })
            this.setState({
                milestones
            });
        }
        this.setState({
            ...edit,
            user:edit.user && edit.user._id
        },()=>{
            this.prepareData()
        });
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+id);
        debugger
        if(response && response.status === 200){
            this.updateValues(response.data.data)
            this.setState({
                    isLoading: false,
                }
            );
        }
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("expert");
        if(responseuni){
            const data = responseuni.data.data;
            let expertlist = []
            data.map(p =>{
                    expertlist.push( { value: p._id, label: p.full_name})
                }
            )

            this.setState({
                expertlist: expertlist
            });
        }


    }







    renderMileStone(){
        const {milestones} = this.state
        return (<div>

            {milestones.map((item,index)=>{

                let value = this.milestonestitle && this.milestonestitle[index]
                let milestonestitle = ""
                if(value){
                    milestonestitle =  value.value;
                }

                value = this.milestonesdescription && this.milestonesdescription[index]
                let milestonesdescription = ""
                if(value){
                    milestonesdescription =  value.value;
                }

                value = this.milestonesduration && this.milestonesduration[index]
                let milestonesduration = ""
                if(value){
                    milestonesduration =  value.value;
                }

                value = this.milestonescost && this.milestonescost[index]
                let milestonescost = ""
                if(value){
                    milestonescost =  value.value;
                }

                value = this.milestonesmarkup && this.milestonesmarkup[index]
                let milestonesmarkup = "0"
                if(value){
                    milestonesmarkup =  value.value;
                }





                return  <div key={index}>
                    <div>

                        <Row><Label sm={2}> <b>Miltestone #</b></Label>
                            <Col sm={1} style={{}}>
                                <FormGroup >
                                   {1+index}
                                </FormGroup>
                            </Col>


                        </Row>

                    </div>

                    <FormRow label="Miltestone Title" viewonly={true} name={"milestonestitle"+index} data={milestonestitle}    />
                    <FormRow label="Miltestone Description" viewonly={true} type={"textarea"} name={"milestonesdescription"+index} data={milestonesdescription}     />

                    <Row>
                        <Col md="4" >
                            <FormRow label="Cost" viewonly={true} type={"number"} name={"milestonescost"+index} data={milestonescost}     />
                        </Col>


                        <Col md="4" >
                            <FormRow label="Duration" viewonly={true} type={"number"} className={"mr-2"} name={"milestonesduration"+index} data={milestonesduration}    />

                        </Col>

                        <Col md="4" >
                            <FormRow label="Markup" viewonly={true} required={false} type={"number"} name={"milestonesmarkup"+index} data={milestonesmarkup}     />
                        </Col>
                    </Row>
                    <hr/>
                </div>

            })}




        </div>)
    }


    render() {
        // taking all the states
        const {loading,isLoading,  title,description,user,
            assigned,
            clientlist,
            expertlist,
            expertise,
            status,
            budget,
            duration,
            comment ,currency}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Title" viewonly={true}  name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="Currency"  viewonly={true}  name={"currency"}  defaultValue={currency}  data={this.createSelectValue(["USD","LBP"])} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="expert" viewonly={true} name="user"  defaultValue={user}  data={expertlist} changeInput={this.changeInput.bind(this)} />



                                        <SelectRow label="status"  viewonly={true} name="status"  defaultValue={status}  data={this.createSelectValue(["pending","approved","canceled"])} changeInput={this.changeInput.bind(this)} />


                                        <FormRow label="comments"  viewonly={true} name="comments" data={comment}  type={"textarea"}  changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardHeader>
                                        <CardTitle tag="h4">Milestones</CardTitle>
                                    </CardHeader>

                                    <CardBody>


                                        {this.renderMileStone()}

                                        <CardFooter className="text-center">
                                            <Button   onClick={()=>{
                                                this.props.history.goBack();
                                            }}> Back</Button> &nbsp;
                                        </CardFooter>
                                    </CardBody>


                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


