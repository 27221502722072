const employmentstatuslist = ["Freelance","Full time","Part Time","Unemployed"];
const sectoroperatorin = ["Bakeries","Meat & poultry","Seafood","Cereals & Grains","Nuts & Crackers","Canned & RTE Food","Beverages","Seasoning & Powder","Fruits & Vegetables","Other"];
const titlelist =["Mr","Mrs","Ms"];
const educationlist = ["BS","MS","PhD","Other"];
const expertiselist = ["Fresh Graduate","Junior (3-5 years of experience)","Senior (5-10 years of experience)","Managerial (10+ years of experience)"]
const categorylist = ["Research & Development, Process Optimization &  New Products Designs","Design & Documentation of Food Safety Management Systems","Food Safety & Quality Systems Pre-Certification & Audits Preparation","Technical Assistance on Food Trade Regulations","Training","Other"]

export {
    employmentstatuslist,
    sectoroperatorin,
    titlelist,
    educationlist,
    expertiselist,
    categorylist
}
