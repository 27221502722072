import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Row,
    Col, Button
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import {selectCountryList} from "../../core/countries";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import FileUpload from "../../components/file-upload/file-upload.component";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

export default class RequestView extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title:"",
            description:"",
            startdate:"",
            othercategorydescription:"",
            deadline:"",
            _id:"",
            loading : false,
            status:"",
            category:"",
            randdtype:"",
            user:"",
            randdproducts:"",
            currency:"USD",
            assigned:[],
            clientlist:[],
            expertlist:[],

            attachements:[],
            attachments_url:[],
            expertise:"",
            budget:"",
            duration:"",
            comments:"",

            randdprojectstatus:"",
            randdlevel:"",
            randdchallenge:"",
            fsmstype:"",
            fsmslevel:"",
            fsmschallenge:"",
            fsmsmeasure:"",
            fsmsdoc:"",
            certificationtype:"",
            certificationlevel:"",
            certificationchallenge:"",
            certificationmeasure:"",
            certificationdoc:"",
            certificationexpected:"",
            tradetype:"",
            tradecountry:[],
            tradetime:"",
            traderequest:"",
            tradeactivities:"",
            tradeproject:"",
            trainingtype:"",
            trainingtopic:"",
            trainingnumber:"",
            trainingwork:"",
            traininglevel:"",
            trainingpreivous:"",
            trainingtooltip:"",
            traininglocationother:"",
            trainingtimeline:"",
            trainingdaytime:"",
            traininglocation:"",

            randdchallengeyes:"",
            fsmsdocyes:"",
            fsmschallengeyes:"",
            certificationchallengeyes:"",
            certificationdocyes:"",
            trainingpreivousyes:"",

            modalClassic: false,
        };
        this.pathname = "request";
        this.engine = new RequestEngine();

        this.idFileBase64Attach = [];
    }


    componentDidMount() {

        const edit = this.props.location.data
        if (edit){

            this.setState({
                ...edit,
                user:edit.user && edit.user._id,
            },()=>{
                this.prepareData()
            });
        }else{
            this.prepareData()
        }
        this.callPage()
    }

    renderRandD() {

        const {
            randdchallengeyes,
            randdtype,
            randdproducts,
            randdprojectstatus,
            randdlevel,
            randdchallenge}= this.state;
        return (<div>
                    <SelectRow viewonly={true} label="Type of optimization requested"  required={false}   name="randdtype"  className={"mr-2"} defaultValue={randdtype}  data={this.createSelectValue(["shelf life/quality/packaging optimization of existing product","new product development ","process line optimization ","trials","other"])} changeInput={this.changeInput.bind(this)} />

              <FormRow label="Products"  viewonly={true} name="randdproducts" data={randdproducts} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

            <SelectRow viewonly={true} label="Project status "  required={false}   name="randdprojectstatus"  className={"mr-2"} defaultValue={randdprojectstatus}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the research/trials/optimization "])} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Description of recipes or process steps & conditions"  name="randdlevel" data={randdlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Challenge/issue/problem faced"  required={false}   name="randdchallenge"  className={"mr-2"} defaultValue={randdchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                       {randdchallenge=="yes" && <FormRow viewonly={true} label="Please Write it here" required={true} name="randdchallengeyes" data={randdchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


        </div>)

    }
    renderFSMS() {

        const {
            fsmstype,
            fsmslevel,
            fsmschallenge,
            fsmsmeasure,
            fsmsdoc,}= this.state;
        return (<div>

                    <FormRow viewonly={true} label="Type of service"  name="fsmstype" data={fsmstype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="High level process steps & conditions surrounding the production line"  name="fsmslevel" data={fsmslevel} className={"mr-2"}     />

                    <SelectRow viewonly={true} label="Challenge/issue/problem faced"  required={false}   name="fsmschallenge"  className={"mr-2"} defaultValue={fsmschallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Current measures taken"  name="fsmsmeasure" data={fsmsmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Documentation in place"  required={false}   name="fsmsdoc"  className={"mr-2"} defaultValue={fsmsdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />

        </div>)

    }
    renderCERTIFICATION() {

        const {
            certificationtype,
            certificationlevel,
            certificationchallenge,
            certificationmeasure,
            certificationdoc,
            certificationexpected,}= this.state;
        return (<div>
                    <SelectRow viewonly={true} label="Type of service"  required={false}   name="certificationtype"  className={"mr-2"} defaultValue={certificationtype}  data={this.createSelectValue(["gap analysis"," pre-certification","pre-audit "])} changeInput={this.changeInput.bind(this)} />
                    <FormRow viewonly={true} label="High level process steps & conditions"  name="certificationlevel" data={certificationlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    <SelectRow viewonly={true} label="Challenge/issue/problem faced"  required={false}   name="certificationchallenge"  className={"mr-2"} defaultValue={certificationchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    <FormRow viewonly={true} label="Current measures taken"  name="certificationmeasure" data={certificationmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    <SelectRow viewonly={true} label="Documentation in place"  required={false}   name="certificationdoc"  className={"mr-2"} defaultValue={certificationdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    <SelectRow viewonly={true} label="Certification expected "  required={false}   name="certificationexpected"  className={"mr-2"} defaultValue={certificationexpected}  data={this.createSelectValue(["HACCP","ISO22000","FSSC22000","ISO9001","BRC","Global GAP"," Organic certification"])} changeInput={this.changeInput.bind(this)} />

        </div>)

    }
    renderTrade() {

        const {
            tradetype,
            tradecountry,
            tradetime,
            traderequest,
            tradeactivities,
            tradeproject}= this.state;
        return (<div>
                    <SelectRow viewonly={true} label="Type of service"  required={false}   name="tradetype"  className={"mr-2"} defaultValue={tradetype}  data={this.createSelectValue(["import","export"])} changeInput={this.changeInput.bind(this)} />

                     <SelectRow label="Country" viewonly={true}  isMulti={true}  required={true}   name="tradecountry"  className={"mr-2"} defaultValue={tradecountry}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Time in trade"  required={false}   name="tradetime"  className={"mr-2"} defaultValue={tradetime}  data={this.createSelectValue(["import","export"])} changeInput={this.changeInput.bind(this)} />


                    <SelectRow viewonly={true} label="Request"  required={false}   name="traderequest"  className={"mr-2"} defaultValue={traderequest}  data={this.createSelectValue(["food safety & quality regulations compliance (FDA, EU, CFIA, GSO..."," label creatio","label review","packaging compliance","other"])} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Activities/Products"  name="tradeactivities" data={tradeactivities} className={"mr-2"} type={"textarea"}    changeInput={this.changeInput.bind(this)} />



                    <SelectRow viewonly={true} label="Project status"  required={false}   name="tradeproject"  className={"mr-2"} defaultValue={tradeproject}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the compliance/labelling/packaging - please specify what activities you have already implemented in relation to this project request "])} changeInput={this.changeInput.bind(this)} />


        </div>)

    }
    renderTraining() {

        const {
            trainingtype,
            trainingtopic,
            trainingnumber,
            trainingwork,
            traininglevel,
            trainingpreivousyes,
            trainingpreivous,
            traininglocationother,
            trainingtooltip,
            trainingtimeline,
            trainingdaytime,
            traininglocation}= this.state;
        return (<div>
                    <SelectRow viewonly={true} label="Type of training"  required={false}   name="trainingtype"  className={"mr-2"} defaultValue={trainingtype}  data={this.createSelectValue(["Individual","company","cluster/group"])} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Topic of training"  name="trainingtopic" data={trainingtopic} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Number of trainees" type={"number"}  name="trainingnumber" data={trainingnumber} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />


                    <FormRow viewonly={true} label="Type of work the trainees perform"  name="trainingwork" data={trainingwork} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Level of knowledge of the trainees"  name="traininglevel" data={traininglevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Previous training received"  required={false}   name="trainingpreivous"  className={"mr-2"} defaultValue={trainingpreivous}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
            {trainingpreivous=="yes" && <FormRow viewonly={true}  label="Please Write it here"  name="trainingpreivousyes" data={trainingpreivousyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


                    <FormRow viewonly={true} label="Specific consideration"  name="trainingtooltip" data={trainingtooltip} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Timeline"  required={false}   name="trainingtimeline"  className={"mr-2"} defaultValue={trainingtimeline}  data={this.createSelectValue(["one-time training","recurrent training "])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="day time"  required={false}   name="trainingdaytime"  className={"mr-2"} defaultValue={trainingdaytime}  data={this.createSelectValue(["am","pm"])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Location to conduct the training"  required={false}   name="traininglocation"  className={"mr-2"} defaultValue={traininglocation}  data={this.createSelectValue(["online","in person"])} changeInput={this.changeInput.bind(this)} />
            {traininglocation=="in person" && <FormRow viewonly={true} label="Please specify the location"  name="traininglocationother" data={traininglocationother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

        </div>)

    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+id);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    ...response.data.data,
                }
            );
        }
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("client");
        if(responseuni){
            const data = responseuni.data.data;
            let clientlist = []
            data.map(p =>{
                    clientlist.push( { value: p._id, label: p.full_name})
                }
            )

            this.setState({
                clientlist: clientlist
            });
        }

        responseuni = await this.engine.getItemlistDirect("expert");
        if(responseuni){
            const data = responseuni.data.data;
            let expertlist = []
            data.map(p =>{
                    expertlist.push( { value: p._id, label: p.full_name})
                }
            )

            this.setState({
                expertlist: expertlist
            });
        }


    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        debugger
        let data = {...this.state}


        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading,  title,attachments_url,user,
            assigned,
            clientlist,
            expertlist,
            expertise,
            status,
            budget,
            category,
            duration,
            currency,
            deadline,
            outcome,startdate,
            comments ,othercategory,othercategorydescription}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Title"   viewonly={true} name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="client" viewonly={true}  name="user"  defaultValue={user}  data={clientlist} changeInput={this.changeInput.bind(this)} />



                                        <SelectRow label="status"  viewonly={true} name="status"  defaultValue={status}  data={this.createSelectValue(["pending","approved","canceled","awarded","awarded/pending payment confirmation"])} changeInput={this.changeInput.bind(this)} />



                                        <SelectRow label="Category" viewonly={true} info={"Choose any category that most closely resembles your request/project, and if it fits multiple or not, choose the closest option and explain the rest in the text field below."} name="category"  defaultValue={category}  data={this.createSelectValue(["Research & Development, Process Optimization &  New Products Designs","Design & Documentation of Food Safety Management Systems","Food Safety & Quality Systems Pre-Certification & Audits Preparation","Technical Assistance on Food Trade Regulations","Training","Other"])} changeInput={this.changeInput.bind(this)} />

                                        <hr/>
                                        {category=="Other" && <FormRow viewonly={true} label="Other Category"  name="othercategory" data={othercategory} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                                        {category=="Other" && <FormRow viewonly={true} label="Project Description "  name="othercategorydescription" data={othercategorydescription} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                                        {category.includes("search") && this.renderRandD()}
                                        {category.includes("ocumentation") && this.renderFSMS()}
                                        {category.includes("ertification") && this.renderCERTIFICATION()}
                                        {category.includes("ssistance") && this.renderTrade()}
                                        {category.includes("aining") && this.renderTraining()}
                                        <hr/>
                                        <SelectRow label="Expertise" viewonly={true} info={"What expertise level suits you"}  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(["Fresh Graduate","Junior (3-5 years of experience)","Senior (5-10 years of experience)","Managerial (10+ years of experience)"])} changeInput={this.changeInput.bind(this)} />


                                        <SelectRow label="Currency"  viewonly={true}  name="currency"  defaultValue={currency}  data={this.createSelectValue(["USD","LBP"])} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"budget ("+currency+")"} viewonly={true}  name="budget" data={budget}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Duration (Days)" viewonly={true} name="duration" data={duration}  type={"number"}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Start date"  viewonly={true} name="startdate" data={startdate}    changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="End Date"  viewonly={true} name="deadline" data={deadline}  type={"date"}  changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="Outcome / Result Expected" viewonly={true} required={false} type={"textarea"} name="outcome" data={outcome}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Additional Description or Comments" viewonly={true} required={false} type={"textarea"} name="comments" data={comments}   changeInput={this.changeInput.bind(this)} />


                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Back</Button> &nbsp;
                                   </CardFooter>


                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


