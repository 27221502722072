import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import {Utilites} from "../../core/Utilites";

import { Stepper } from 'react-form-stepper';
import FileUpload from "../../components/file-upload/file-upload.component";
class ProjectMileStones extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loading : false,
            commentloading:false,
            comments:"",
            attachments_url:[],
            offers:[],
            commentslist:[],
            milestonedeliverables:[],
            milestone:{}
        };
        this.pathname = "milestone";
        this.engine = new RequestEngine();
        this.idFileBase64Attach = [];
    }

    componentDidMount() {
        this.callPage();
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        debugger

        const mielstoneid = this.props.milestone._id
        const response = await  this.engine.getItem("milestone","/info/"+mielstoneid);
        if(response && response.status === 200){
            debugger
            this.setState({
                    isLoading: false,
                milestone:response.data.data.milestone,
                commentslist:response.data.data.commentslist,
                milestonedeliverables:response.data.data.milestonedeliverables,

                }
            );
        }
        this.props.loadingAction(false);
    }


    async processmilestone(activestep){
        const data={
            id:this.props.milestone._id,
            activestep:activestep
        }

        this.setState({
                loading: true,
            }
        );
        const response = await  this.engine.postItem("milestone","/process",data);
        if(response && response.status === 200){
            this.setState({
                loading: false,
                milestone:response.data.data.milestone,
                },()=>{
                    Utilites.showSucessMessage("Started!")

                this.callPage();
                   // this.props.history.push('/admin/request/archived');

                }
            );
        }
       // this.props.loadingAction(false);
    }


    async processcomments(){
        const data={
            id:this.props.milestone._id,
            comments:this.state.comments,
            attachements : this.idFileBase64Attach
        }

        this.setState({
            commentloading: true,
            }
        );
        const response = await  this.engine.postItem("milestone","/comment",data);
        debugger
        if(response && response.status === 200){
            this.setState({
                commentloading: false,
                comments:"",
                commentslist:response.data.data.commentslist,
                },()=>{
                    Utilites.showSucessMessage("Commented!")
                }
            );
        }
        // this.props.loadingAction(false);
    }


    updateUploadedFiles(files){
        this.idFileBase64Attach = [];
        files.map(i=>{
            if(!i.url){
                Utilites.getFileBase64(i, (result) => {
                    this.idFileBase64Attach.push({base64:result,name:i.name});
                });
            }else{
                this.idFileBase64Attach.push(i);
            }
        })
    }

    render() {


        const {isLoading,milestonedeliverables,milestone,commentslist }= this.state;
        const {number} = this.props;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <Card>

                <Stepper
                    steps={[{ label: 'To Do' }, { label: 'In Progress' }, { label: 'Client Validation' },{ label: 'Done' }]}
                    activeStep={milestone.activestep}
                    ConnectorStyleProps={{
                        activeColor:"#3E50B5"
                    }}
                    activeColor={"#3E50B5"}
                    completedColor={"#3E50B5"}
                />
                <CardHeader>
                    <CardTitle tag="h5" style={{fontWeight:"bold"}} className={"boldmin boldx"}>Milestone {number}</CardTitle>
                    <Row>
                        <Col md="4">
                            <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Duration (Days): <span style={{fontWeight:"normal"}}>{milestone.duration}</span></CardTitle>
                        </Col>
                        <Col md="4">
                            <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Cost (USD): <span style={{fontWeight:"normal"}}>{milestone.cost}</span></CardTitle>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                          <CardTitle tag="h6"  style={{fontWeight:"bold"}}>Description </CardTitle>

                            <CardTitle tag="h6"  style={{fontWeight:"normal"}}>
                                <br/>
                        <div  dangerouslySetInnerHTML={{__html: milestone.description}}></div>
                            </CardTitle>
                        </Col>
                    </Row>

                    <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">

                        <CardTitle tag="h6"  style={{fontWeight:"bold"}}>Activity </CardTitle>
                        { commentslist && commentslist.map(i=>{
                            return  <div>

                                <div>
                                    <b>{i.user && i.user.first+" "+i.user.last}</b> <br/>
                                    {i.text}
                                    <br/>

                                    <i>{this.renderDate(i.createdAt)}</i>
                                </div>
                                <br/>
                                {i.attachments_url.length>0 && <ul style={{paddingLeft: 13}}>
                                    {i.attachments_url.map((ii,index)=>{
                                        return <li key={index}><a target={"_blank"} href={ii.url}>{ii.name}</a> </li>
                                    })}
                                </ul>}
                                <hr/>
                            </div>
                        })}




                        {milestonedeliverables && milestonedeliverables.length>0  &&<div>Milestone deliverables</div>}
                        {milestonedeliverables && milestonedeliverables.length>0 && milestonedeliverables.map(i=>{
                            return i.attachments_url.map((ii,index)=>{
                                return <li key={index}><a target={"_blank"} href={ii.url}>{ii.name}</a> </li>
                            })
                        })}
                    </AvForm>


                    <div className="pull-right floatbtn" style={{width:390,padding:10}}>



                    </div>
                </CardHeader>


            </Card>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ProjectMileStones);

