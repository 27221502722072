import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Row,
    Modal,
    Col, Button
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import SelectRow from "../../components/Select/SelectRow"
import {AvForm, AvGroup} from 'availity-reactstrap-validation';

import {Utilites} from "../../core/Utilites";
import FormRow from "../../components/Row/FormRow";

export default class PaymentEdit extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            _id:"",
            loading : false,
            lastpayment:"initial payment",
            status:"",
            user:{},
            request:{},
            offer:{},


            modalClassic: false,
        };
        this.pathname = "payment";
        this.engine = new RequestEngine();

    }




    componentDidMount() {

        const edit = this.props.location.data
        if (edit){

            let lastpayment = "last payment";
            if(!edit.lastpayment){
                lastpayment = "initial payment"
            }
            this.setState({
                ...edit,
                lastpayment
            });
        }
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await this.engine.getItem(this.pathname, "/info/" + id);
        if (response && response.status === 200) {
            this.setState({
                    isLoading: false,
                    ...response.data.data,
                }
            );
        }
    }





    handleValidSubmit = async () => {


        debugger
        this.setState({
            loading: true
        })


        let data = {...this.state}

        if(data.lastpayment.includes("last")){
            data.lastpayment = true;
        }else{
            data.lastpayment = false
        }

        const response = await this.engine.saveItem(this.pathname+"/updatestatus",data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading,status,lastpayment,user, request,offer}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }

        let paymentstatuslist = ["submitted","pending","received","rejected","pending configuration"];
        if(user && user.usertype=="expert"){
            paymentstatuslist = ["submitted","pending","received","rejected"];
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Payment Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow viewonly={true} label="user"  name="user" data={user.full_name} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                                        <FormRow viewonly={true} label="user type"  name="user" data={user.usertype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                                        {user.usertype=="client" && <FormRow viewonly={true} label="request"  name="user" data={request.title} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                                        {user.usertype=="expert" && <FormRow viewonly={true} label="offer"  name="user" data={offer.title} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


                                        <SelectRow label="status"  name="status"  defaultValue={status}  data={this.createSelectValue(paymentstatuslist)} changeInput={this.changeInput.bind(this)} />
                                        {user.usertype=="client" && <SelectRow label="Payment Type"  name="lastpayment"  defaultValue={lastpayment}  data={this.createSelectValue(["last payment","initial payment"])} changeInput={this.changeInput.bind(this)} />}



                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Cancel</Button> &nbsp;
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>


                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


