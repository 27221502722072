import React from "react";
import Dashboard from "views/Dashboard.jsx";
import Login from "views/login/Login.jsx";
import ManagerList from "./views/manager/ManagerList";
import EditSettings from "./views/settings/EditSettings";
import ManagerAddUpdate from "./views/manager/ManagerAddUpdate";



import ClientAddUpdate from "./views/client/ClientAddUpdate";
import ClientList from "./views/client/ClientList";
import ClientView from "./views/client/ClientView";


import ExpertAddUpdate from "./views/expert/ExpertAddUpdate";
import ExpertList from "./views/expert/ExpertList";
import ExpertView from "./views/expert/ExpertView";



import RequestList from "./views/request/RequestList";
import RequestAddUpdate from "./views/request/RequestAddUpdate";


import OfferList from "./views/offer/OfferList";
import OfferListAddUpdate from "./views/offer/OfferListAddUpdate";
import RequestView from "./views/request/RequestView";
import OfferView from "./views/offer/OfferView";
import ProjectList from "./views/project/ProjectList";
import ProjectView from "./views/project/ProjectView";
import LogList from "./views/log/LogList";
import PaymentList from "./views/payment/PaymentList";
import PaymentEdit from "./views/payment/PaymentEdit";







const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,

    menu: true,
    layout: "/admin"
  },

    {
        path: "/client",
        name: "Clients",
        menu: true,
        allow : ["admin","sales","manager"],
        icon: "nc-icon nc-single-02",
        component: ClientList,
        layout: "/admin"
    },
    {
        path: "/request",
        name: "Clients Request",
        menu: true,
        allow : ["admin","sales","manager"],
        icon: "nc-icon nc-layout-11",
        component: RequestList,
        layout: "/admin"
    },
    {
        path: "/expert",
        name: "Experts",
        menu: true,
        allow : ["admin","manager"],
        icon: "nc-icon nc-single-02",
        component: ExpertList,
        layout: "/admin"
    },

    {
        path: "/offer",
        name: "Experts Offer",
        menu: true,
        allow : ["admin","manager"],
        icon: "nc-icon nc-paper",
        component: OfferList,
        layout: "/admin"
    },
    {
        path: "/project",
        name: "Project",
        menu: true,
        allow : ["admin","manager"],
        icon: "nc-icon nc-paper",
        component: ProjectList,
        layout: "/admin"
    },


    {
        path: "/manager",
        name: "Manager",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: ManagerList,
        layout: "/admin"
    },

    {
        path: "/logs",
        name: "Logs",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: LogList,
        layout: "/admin"
    },
    {
        path: "/payment",
        name: "Payment",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: PaymentList,
        layout: "/admin"
    },




    {
        path: "/login",
        name: "Login",
        mini: "L",
        menu: false,
        component: Login,
        layout: "/auth"
    },

    {
        path: "/offer/edit/:id",
        name: "Edit offer",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: OfferListAddUpdate,
        layout: "/admin"
    },
    {
        path: "/offer/view/:id",
        name: "View offer",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: OfferView,
        layout: "/admin"
    },
    {
        path: "/payment/edit/:id",
        name: "Edit Payment",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: PaymentEdit,
        layout: "/admin"
    },

    {
        path: "/project/view/:id",
        name: "View project",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ProjectView,
        layout: "/admin"
    },


    {
        path: "/offer/add",
        name: "add offer",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: OfferListAddUpdate,
        layout: "/admin"
    },

    {
        path: "/request/edit/:id",
        name: "Edit request",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RequestAddUpdate,
        layout: "/admin"
    },
    {
        path: "/request/view/:id",
        name: "view request",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RequestView,
        layout: "/admin"
    },

    {
        path: "/request/add",
        name: "add request",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RequestAddUpdate,
        layout: "/admin"
    },



    {
        path: "/manager/edit/:id",
        name: "Edit manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerAddUpdate,
        layout: "/admin"
    },
    {
        path: "/manager/add",
        name: "add manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerAddUpdate,
        layout: "/admin"
    },

    {
        path: "/client/edit/:id",
        name: "Edit client",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ClientAddUpdate,
        layout: "/admin"
    },
    {
        path: "/client/view/:id",
        name: "Edit client",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ClientView,
        layout: "/admin"
    },

    {
        path: "/client/add",
        name: "add client",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ClientAddUpdate,
        layout: "/admin"
    },


    {
        path: "/expert/edit/:id",
        name: "Edit expert",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ExpertAddUpdate,
        layout: "/admin"
    },
    {
        path: "/expert/view/:id",
        name: "View expert",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ExpertView,
        layout: "/admin"
    },

    {
        path: "/expert/add",
        name: "add expert",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ExpertAddUpdate,
        layout: "/admin"
    },


    {
        path: "/settings",
        name: "Settings",
        menu: false,
       // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: EditSettings,
        layout: "/admin"
    },


];



export default routes;
