import React from "react";


import {employmentstatuslist,sectoroperatorin,titlelist,educationlist,expertiselist} from "../../core/staticdata";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup, Button, CardFooter
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import ImageUpload from "../../components/CustomUpload/ImageUpload";

import SelectRow from "../../components/Select/SelectRow";
import {selectCountryList} from "../../core/countries";

export default class ExpertView extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            title:"",
            first:"",
            last:"",
            email:"",
            password:"",
            birthday:"",
            gender:"",
            major:"",
            company:"",
            country:"",
            interestedin:"",
            educational:"",
            phone:"",
            expertise:"",
            projectworkbefore:"",
            systemexperiencewith:"",
            cv:null,
            filename:"",
            fullcv:"",
            password2:"",
            employmentstatus:"",
            loading:false,
            showother:false,
            sectors:[],
            othersector:"",

            city:"",
            district:"",
            street:"",
            buildingname:"",
            banka_benfname:"",
            banka_iban:"",
            banka_number:"",
            banka_name:"",
            banka_swift:"",
            preferedpaymentoption:"",
        };
        this.pathname = "expert";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        debugger
        if (edit){
            debugger
            this.setState({
                ...edit,
            });
        }

        this.callPage()
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+id);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    ...response.data.data,
                }
            );
        }
    }



    renderExpertInfo(){
        const {
            birthday,
            educational,
            employmentstatus,
            expertise,
            projectworkbefore,
            major,
            gender,
            systemexperiencewith} = this.state

        return (<>
                    <SelectRow viewonly={true} label="Employement status"  className={"mr-2"} name="employmentstatus"  defaultValue={employmentstatus}  data={this.createSelectValue(employmentstatuslist)} changeInput={this.changeInput.bind(this)} />


                    <SelectRow viewonly={true} label="Gender"  name="gender"  className={"mr-2"} defaultValue={gender}  data={this.createSelectValue(["Male","Female"])} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Date of Birth" type={"date"} name="birthday" data={birthday}   changeInput={this.changeInput.bind(this)} />


                    <SelectRow viewonly={true} label="Educational Background"  name="educational"  defaultValue={educational}   data={this.createSelectValue(educationlist)} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="Major"  name="major" data={major}   changeInput={this.changeInput.bind(this)} />

                    <SelectRow viewonly={true} label="Expertise Level"  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(expertiselist)} changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="What are the food safety and quality projects you have worked on before" type={"textarea"} name="projectworkbefore" data={projectworkbefore}   changeInput={this.changeInput.bind(this)} />

                    <FormRow viewonly={true} label="What are the food safety system you have experience in ?" type={"textarea"} name="systemexperiencewith" data={systemexperiencewith}   changeInput={this.changeInput.bind(this)} />
            <ImageUpload viewonly={true} filename={this.state.filename} hideplaceholder={true} isfile={true} placeholder={this.state.fullcv} labelupload="Select CV" onChange={(e) => this.onChangeFile(e)}/>


        </>)
    }


    render() {
        // taking all the states
        const {title,
            first,
            last,
            email,
            gender,
            company,
            birthday,
            systemexperiencewith,
            projectworkbefore,
            expertise,
            educational,
            password,
            usertype,
            phone,
            loading,
            sectors,
            showother,
            othersector,
            showconfirmation,

            city,
            district,
            street,
            buildingname,
            banka_benfname,
            banka_iban,
            banka_number,
            banka_name,
            banka_swift,
            country,
            isLoading,
            preferedpaymentoption
        } = this.state

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm   className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Basic Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>



                                        <SelectRow label="Title"  viewonly={true}  name="title"  defaultValue={title}  data={this.createSelectValue(["Mr","Mrs","Ms"])}  changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="gender"   viewonly={true}  name="gender"  defaultValue={gender}  data={this.createSelectValue(["Male","Female"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="First" viewonly={true}  readonly={true} name="first" data={first}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="last" viewonly={true}  readonly={true} name="last" data={last}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="email" viewonly={true}  readonly={true} name="email" data={email}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="phone" viewonly={true}  readonly={true} name="phone" data={phone}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="educational" viewonly={true}  readonly={true} name="educational"  defaultValue={educational}  data={this.createSelectValue(["Freelance","Full time","Part Time","Unemployed"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="birthday" viewonly={true}   readonly={true} name="birthday" data={birthday}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="company" viewonly={true}  readonly={true} name="company" data={company}   changeInput={this.changeInput.bind(this)} />

                                    </CardBody>



                                    <CardHeader>
                                        <CardTitle tag="h4">Address</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <SelectRow label="country"  viewonly={true}  name="country"  defaultValue={country}   data={selectCountryList} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="city" viewonly={true}  readonly={true} name="city" data={city}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="district" viewonly={true}  readonly={true} name="district" data={district}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="street" viewonly={true}  readonly={true} name="street" data={street}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="building name" viewonly={true}  readonly={true} name="buildingname" data={buildingname}   changeInput={this.changeInput.bind(this)} />

                                    </CardBody>
                                    <CardHeader>
                                        <CardTitle tag="h4">Experience</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="expertise" viewonly={true}  name="expertise" readonly={true} data={expertise}  type={"textarea"}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="system experience with" viewonly={true}  readonly={true} name="systemexperiencewith" data={systemexperiencewith}  type={"textarea"}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="project work before" viewonly={true}  readonly={true} name="projectworkbefore"  data={projectworkbefore}  type={"textarea"}  changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="Payment option"  name="preferedpaymentoption" viewonly={true}  defaultValue={preferedpaymentoption}  data={this.createSelectValue(["wire transfer","OMT cash transfer","physical payment"])} changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    {preferedpaymentoption.includes("wire") && <>
                                        <CardHeader>
                                            <CardTitle tag="h4">Bank Account Details</CardTitle>
                                        </CardHeader>
                                        <CardBody>

                                            <FormRow label="bank name" viewonly={true}  readonly={true} name="banka_name" data={banka_name}   changeInput={this.changeInput.bind(this)} />
                                            <FormRow label="beneficiary name" viewonly={true}  readonly={true} name="banka_benfname" data={banka_benfname}   changeInput={this.changeInput.bind(this)} />
                                            <FormRow label="swift" viewonly={true}  readonly={true} name="banka_swift" data={banka_swift}   changeInput={this.changeInput.bind(this)} />
                                            <FormRow label="bank account number" viewonly={true}  readonly={true} name="banka_number" data={banka_number}   changeInput={this.changeInput.bind(this)} />
                                            <FormRow label="iban" viewonly={true}  readonly={true} name="banka_iban" data={banka_iban}   changeInput={this.changeInput.bind(this)} />

                                        </CardBody>
                                    </>}


                                    <CardBody>

                                                <SelectRow viewonly={true} isMulti={true} label="What sector do you have experience"  name="sectors"  defaultValue={sectors}  data={this.createSelectValue(sectoroperatorin)}  />


                                        {showother && <FormRow label="Other" viewonly={true} name="othersector" data={othersector}    />}
                                        {this.renderExpertInfo()}


                                        <CardFooter className="text-center">
                                            <Button   onClick={()=>{
                                                this.props.history.goBack();
                                            }}> Back</Button> &nbsp;
                                        </CardFooter>
                                    </CardBody>

                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


