import React from "react";


import {employmentstatuslist,sectoroperatorin,titlelist,educationlist,expertiselist} from "../../core/staticdata";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup, Button
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import ImageUpload from "../../components/CustomUpload/ImageUpload";

import {Utilites} from "../../core/Utilites";
import SelectRow from "../../components/Select/SelectRow";
import {selectCountryList} from "../../core/countries";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";


class ClientAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            title:"",
            first:"",
            last:"",
            email:"",
            password:"",
            birthday:"",
            gender:"",
            major:"",
            company:"",
            country:"",
            interestedin:"",
            educational:"",
            phone:"",
            expertise:"",
            projectworkbefore:"",
            systemexperiencewith:"",
            cv:null,
            filename:"",
            fullcv:"",
            password2:"",
            employmentstatus:"",
            loading:false,
            showother:false,
            sectors:[],
            othersector:"",

            city:"",
            district:"",
            street:"",
            buildingname:"",
            banka_benfname:"",
            banka_iban:"",
            banka_number:"",
            banka_name:"",
            banka_swift:""


        };
        this.engine = new RequestEngine();
        this.idFileBase64 = "";
        this.pathname = "client";
    }



    componentDidMount() {
        this.callPage()
    }

    onChangeFile(e) {
        debugger
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
        this.setState({cv:e.target.files[0],filename:e.target.files[0].name})
    }
    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem("user","/info/"+id);

        if(response && response.status === 200){


            this.setState({
                    isLoading: false,
                    ...response.data.data
                }
            );
        }
        this.props.loadingAction(false);
    }

    async updateUser(){


        try {
            this.setState({loading : true})
            let data = {...this.state};

            data.cvbase64 = this.idFileBase64;
            const id = this.props.match.params.id
            const result = await this.engine.postItem("user","/updateprofile/"+id,data)
            if(result.status === 200 && result.data.success){
                this.props.history.push('/admin/'+this.pathname);
            }
        }catch (e) {
            alert("Wrong  info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }

    renderClientInfo(){
        const {company, country,interestedin} = this.state

        return (<>
            <Row>
                <Col md="6" >
                    <FormRow label="Company name" className={"mr-2"} name="company" data={company}   changeInput={this.changeInput.bind(this)} />
                </Col>

            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="What are you interest in" type={"textarea"} name="interestedin" data={interestedin}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
        </>)
    }







    render() {
        const {title,
            first,
            last,
            email,
            password,
            usertype,
            phone,
            loading,
            sectors,
            showother,
            othersector,
            showconfirmation,

            city,
            district,
            street,
            buildingname,
            banka_benfname,
            banka_iban,
            banka_number,
            banka_name,
            banka_swift,
            country,
            isLoading
        } = this.state

        if(isLoading){
            return this.renderProgress();
        }
        return (
            <div className="content english">

                <Row>
                    <Col  lg="12" md="12">
                        <Card className="card-login" style={{marginTop:10,padding:30}}>
                            <AvForm   className="form-horizontal" id="TypeValidation">
                                <CardHeader>
                                    <CardTitle tag="h4">Basic Information</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{marginTop:10}}>
                                        <Col md="4"  >
                                            <SelectRow label="Title" className={"mr-2"} name="title"  defaultValue={title}  data={this.createSelectValue(titlelist)} changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="4"  >
                                            <FormRow label="Your First Name" className={"mr-2"} name="first" data={first}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="4" >
                                            <FormRow label="Your Last Name" name="last" data={last}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md="6" >
                                            <FormRow label="Your Email" className={"mr-2"} name="email" data={email}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="6" >
                                            <FormRow label="Your Phone Number" name="phone" data={phone}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>


                                </CardBody>
                                <CardHeader>
                                    <CardTitle tag="h4">Address Information</CardTitle>
                                </CardHeader>

                                <CardBody>

                                    <Row>

                                        <Col md="6" >
                                            <SelectRow label="Country"  required={false}   name="country"  className={"mr-2"} defaultValue={country}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />

                                        </Col>
                                        <Col md="6" >
                                            <FormRow label="City" required={false}  name="city" data={city}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="6" >
                                            <FormRow label="District" required={false}  name="district" data={district} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />

                                        </Col>
                                        <Col md="6" >
                                            <FormRow label="Street" required={false}  name="street" data={street}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" >
                                            <FormRow label="Building name" required={false}  name="buildingname" data={buildingname}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>



                                </CardBody>
                                {usertype=="expert" && <div>
                                    <CardHeader>
                                        <CardTitle tag="h4">Bank Account Details</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="6" >
                                                <FormRow label="Beneficary name" required={false} name="banka_benfname" data={banka_benfname} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="6" >
                                                <FormRow label="IBAN" required={false} name="banka_iban" data={banka_iban}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6" >
                                                <FormRow label="Bank Account Number" required={false} name="banka_number" data={banka_number} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="6" >
                                                <FormRow label="Bank Name" required={false} name="banka_name" data={banka_name}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" >
                                                <FormRow label="Bank Swift" required={false} name="banka_swift" data={banka_swift}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </div>}



                                <CardHeader>
                                    <CardTitle tag="h4">Complete Your Profile</CardTitle>
                                </CardHeader>

                                <CardBody>

                                    <Row>
                                        <Col md="12"  >
                                            <SelectRow isMulti={true} label="What sector do you have experience"  name="sectors"  defaultValue={sectors}  data={this.createSelectValue(sectoroperatorin)} changeInput={(e)=>{
                                                this.setState({ sectors: e })
                                                const otherfound = e.find(i=>i.value.toLowerCase()=="other");
                                                if(otherfound){
                                                    this.setState({
                                                        showother: true
                                                    });
                                                }else{
                                                    this.setState({
                                                        showother: false
                                                    });
                                                }
                                            }} />
                                        </Col>
                                    </Row>


                                    {showother && <Row>
                                        <Col md="12" >
                                            <FormRow label="Other" name="othersector" data={othersector}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>}
                                    {this.renderClientInfo()}


                                </CardBody>
                                <div className={"centrino"}>
                                    <Button   onClick={()=>{
                                        this.props.history.goBack();
                                    }}> Cancel</Button>
                                    <ButtonLoader className=" " onClick={() => this.updateUser()}   style={{
                                        margin: "auto"}} color="primary" loading={loading}>Update</ButtonLoader>
                                </div>
                            </AvForm>

                        </Card>



                    </Col>
                </Row>


            </div>
        );

    }
}


const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ClientAddUpdate);

