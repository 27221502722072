import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import { AvForm } from 'availity-reactstrap-validation';
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import ProjectMileStones from "./ProjectMileStones";
class ViewProject extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            request:{},
            offer:{},
            title:"",
            description:"",
            cancelreason:"",
            expertise:"",
            budget:"",
            duration:"",
            comments:"",
            status:"",
            category:"",
            awardedoffer:false,
            attachments_url:[],
            userid:"",
            user:"",
            _id:"",
            loading : false,
            offers:[],
        };
        this.pathname = "project";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const userid = sessionStorage.getItem('userid');
        if(userid){
            this.setState({
                userid
            })
        }

        this.callPage()
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );

        const requestid = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+requestid);
        if(response && response.status === 200){
            debugger
            this.setState({
                    isLoading: false,
                    ...response.data.data.info,
                }
            );
        }
        this.props.loadingAction(false);
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })



        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };







    render() {
        // taking all the states
        const {isLoading,request,offer,status,cancelreason }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm   className="form-horizontal" id="TypeValidation">

                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h5" style={{fontWeight:"bold"}} className={"boldmin boldx"}>{request.title} </CardTitle>
                                        <div  dangerouslySetInnerHTML={{__html: request.description}}></div>
                                    </CardHeader>
                                </Card>


                                <Row>
                                    <Col md="8">

                                        {offer.milestone && offer.milestone.map((item,index)=>{
                                            return (<ProjectMileStones  milestone={item} key={index} number={index+1} />)
                                        })}



                                    </Col>

                                    <Col md="4">
                                        <Card>
                                            <CardHeader>
                                                <div className="pull-right floatbtn" style={{width:190,padding:10}}>
                                                    <CardTitle tag="h6" className={"status"} >{status}</CardTitle>
                                                </div>
                                                <CardTitle tag="h5" style={{fontWeight:"bold"}} className={"boldmin boldx"}>Project Details</CardTitle>



                                                {cancelreason.length>1 && <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Cancel Reason  </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{cancelreason}</span>
                                                    </Col>
                                                </Row>}

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Project Category  </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{request.category}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Project Expertise  </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{request.expertise}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Budget (USD) </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{offer.cost}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Duration (Days) </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{offer.duration}</span>
                                                    </Col>
                                                </Row>


                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Additional Comments </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{request.comments}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="12">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Attachements </CardTitle>
                                                    </Col>

                                                    <Col md="12">
                                                        <div>
                                                            {request.attachments_url && request.attachments_url.map((i,index)=>{
                                                                return <li key={index}><a target={"_blank"} href={i.url}>{i.name}</a> </li>
                                                            })}
                                                        </div>
                                                    </Col>

                                                </Row>








                                            </CardHeader>
                                        </Card>

                                    </Col>

                                </Row>


                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ViewProject);

