import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col, Button
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";
import {selectCountryList} from "../../core/countries";

export default class ClientView extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title:"",
            first:"",
            last:"",
            phone:"",
            password:"",
            gender : "",
            company:"",
            country:"",
            interestedin:"",
            educational:"",
            expertise:"",
            projectworkbefore:"",
            systemexperiencewith:"",
            usertype:"client",
            birthday:"",
            email:"",
            _id:"",


            city:"",
            district:"",
            street:"",
            buildingname:"",



            banka_swift:"",
            banka_name:"",
            banka_number:"",
            banka_iban:"",
            banka_benfname:"",
            loading : false,



            capcacitysize:"",
            nameofcertification:"",

            showcountries:"",
            witchcountry:[],
        };
        this.pathname = "client";
        this.engine = new RequestEngine();

    }

    componentDidMount() {


        const edit = this.props.location.data
        debugger
        if (edit){
            debugger
            this.setState({
                ...edit,
            });
        }

        this.callPage()
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const id = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+id);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    ...response.data.data,
                }
            );
        }
    }






    render() {
        // taking all the states
        const {loading,isLoading,  title,
            first,
            last,
            phone,
            password,
            gender ,
            company,
            country,
            interestedin,
            educational,
            expertise,
            projectworkbefore,
            systemexperiencewith,
            birthday,
            email,

            city,
            district,
            street,



            numberofyears,
            numberofemployees,
            capcacitysize,
            producttype,
            areyouexporting,
            disgnatedperson,
            anycertificationinsurance,


            loadingdelete,
            sectors,
            showother,
            othersector,
            allowdelete,
            nameofcertification,

            showcountries,
             witchcountry,
            banka_benfname,buildingname}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Basic Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>



                                        <SelectRow label="Title"  viewonly={true} name="title"  defaultValue={title}  data={this.createSelectValue(["Mr","Mrs","Ms"])}  changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="gender"  viewonly={true} name="gender"  defaultValue={gender}  data={this.createSelectValue(["male","female"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="First" viewonly={true} name="first" data={first}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="last" viewonly={true} name="last" data={last}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="email" viewonly={true} name="email" data={email}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="phone" viewonly={true} name="phone" data={phone}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="educational" viewonly={true}  name="educational"  defaultValue={educational}  data={this.createSelectValue(["bt3","brevet"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="birthday" viewonly={true} name="birthday" data={birthday}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="company" viewonly={true} name="company" data={company}   changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardHeader>
                                        <CardTitle tag="h4">Company</CardTitle>
                                    </CardHeader>

                                    <CardBody>

                                        <FormRow label="Company name" viewonly={true} className={"mr-2"} name="company" data={company}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Number of years in business" viewonly={true} className={"mr-2"} name="numberofyears" data={numberofyears}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Number of employees" viewonly={true} className={"mr-2"} name="numberofemployees" data={numberofemployees}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Company capacity size" viewonly={true} type={"textarea"} className={"mr-2"} name="capcacitysize" data={capcacitysize}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Product Types" viewonly={true} type={"textarea"} className={"mr-2"} name="producttype" data={producttype}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="Are you Exporting ?"  viewonly={true} name="areyouexporting"  className={"mr-2"} defaultValue={areyouexporting}  data={this.createSelectValue(["yes","no"])} changeInput={(e)=>{
                                            debugger
                                            this.setState({
                                                areyouexporting:e.value,
                                                showcountries:e.value.includes("yes")
                                            })
                                        }} />
                                        <SelectRow viewonly={true} label="Do you have a designated person for the quality department ?"  name="disgnatedperson"  className={"mr-2"} defaultValue={disgnatedperson}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow viewonly={true} label="Do you have any certification or food safety & quality system in place ?"  name="anycertificationinsurance"  className={"mr-2"} defaultValue={anycertificationinsurance}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow viewonly={true} label="Name the certification and the issuance dates" type={"textarea"} className={"mr-2"} name="nameofcertification" data={nameofcertification}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow viewonly={true} label="What are your current company challenges and what are you interest in ?" type={"textarea"} name="interestedin" data={interestedin}   changeInput={this.changeInput.bind(this)} />

                                    </CardBody>


                                    <CardHeader>
                                        <CardTitle tag="h4">Address</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <SelectRow label="country"  viewonly={true} name="country"  defaultValue={country}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="city" viewonly={true} readonly={true} name="city" data={city}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="district" viewonly={true} readonly={true} name="district" data={district}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="street" viewonly={true} readonly={true} name="street" data={street}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="building name" viewonly={true} readonly={true} name="buildingname" data={buildingname}   changeInput={this.changeInput.bind(this)} />

                                    </CardBody>
                                    <CardHeader>
                                        <CardTitle tag="h4">Other</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="interested in" viewonly={true} readonly={true} name="interestedin" data={interestedin}  type={"textarea"}  changeInput={this.changeInput.bind(this)} />
                                    </CardBody>




                                    <CardFooter className="text-center">
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Back</Button> &nbsp;
                                     </CardFooter>

                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


