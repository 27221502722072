import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import SelectRow from "../../components/Select/SelectRow";

export default class EditSettings extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            locationinterval: 0,
            loading : false,
            branchlist:[],
            branch:"",
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
        this.prepareData();
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("branch");
        if(responseuni){
            const data = responseuni.data.data;
            let branchlist = []
            data.map(p =>{
                branchlist.push( { value: p._id, label: p.name})
                }

            )

            this.setState({
                branchlist: branchlist
            });
        }
    }
    callPage() {
        this.engine.getSettingsinfo((response) => {
            console.log(response.status);
            if(response.status === 200){

                this.setState({
                    isLoading: false,
                    branch: response.data.data.branch,
                });
            }
        });
    }





    typeClick = () => {

        this.setState({
            loading: true
        })

        this.engine.saveSettings(this.state,(response) => {
            console.log(response.status);

            this.setState({
                loading: false
            })

        });

    };

    render() {
        // taking all the states
        const {branch,branchlist,loading,isLoading}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    <Row>
                        <Col md="12">
                            <Form className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Settings</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <SelectRow label="Type"  name="branch"  defaultValue={branch}  data={branchlist} changeInput={this.changeInput.bind(this)} />



                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" onClick={() =>
                                            this.typeClick()
                                        } loading={loading}>Save</ButtonLoader>

                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


